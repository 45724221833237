.footer {
  display: flex;
  justify-content: space-around;
  background-color: #f2f2f2;
  padding: 30px 10px;
  color: #333;
  font-family: Arial, sans-serif;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-logo,
.footer-links,
.footer-socials,
.footer-locations,
.footer-contact,
.footer-phone {
  flex: 1;
}

.footer-logo {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-logo-container {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.footer-logo-image {
  width: 90px;
  height: auto;
}

.footer-logo h3 {
  font-weight: bold;
  margin: 0;
}

.footer-description {
  margin-top: 0.5rem;
  max-width: 400px;
}

.footer-social-logos {
  display: flex;
  gap: 0.5rem;
  font-size: 1.5em;
  margin-top: 0.5rem;
  color: #004aad;
}

.footer-links,
.footer-socials,
.footer-locations,
.footer-contact,
.footer-phone {
  text-align: center;
}

.footer-links h3,
.footer-socials h3,
.footer-locations h3,
.footer-contact h3,
.footer-phone h3 {
  color: #004aad;
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-links ul,
.footer-socials ul,
.footer-locations ul,
.footer-phone ul {
  list-style-type: none;
  padding: 0;
}

.footer-links li,
.footer-socials li,
.footer-locations li,
.footer-phone li {
  margin: 5px 0;
  cursor: pointer;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-contact p {
  margin: 10px;
  font-size: 0.9em;
}

.footer-contact-button {
  background-color: transparent;
  border: 2px solid #333;
  border-radius: 5px;
  padding: 5px 25px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-contact-button:hover {
  background-color: #333;
  color: #fff;
}

@media only screen and (max-width: 760px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 1rem;
  }

  .footer-logo-image {
    width: 70px;
    height: auto;
  }

  .footer-logo h3 {
    font-size: 1.2em;
    margin: 0.5rem 0;
  }

  .footer-description {
    font-size: 0.9em;
    max-width: 300px;
    margin: 0.5rem 0;
    text-align: center; 
  }

  .footer-social-logos,
  .footer-links,
  .footer-socials,
  .footer-locations,
  .footer-contact {
    display: none;
  }

  .footer::after {
    content: "Direitos de autor todos reservados 2024 © CABO AIR CARGO";
    font-size: 0.8em;
    color: #333;
    margin-top: 1rem;
    text-align: center;
  }
}