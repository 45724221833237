.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.loading-logo {
  width: 120px;
  filter: blur(10px);
  opacity: 0.5;
  animation: clearLogo 4s ease-out forwards;
}

@keyframes clearLogo {
  0% {
    filter: blur(10px);
    opacity: 0.5;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}
