.manual-video-player {
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-element {
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    border-radius: 12px;
}

@media (min-width: 1024px) {
    .manual-video-player {
        width: 100%;
        max-width: 100%;
    }
}