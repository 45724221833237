.contact_container {
    width: 100%;
    background-color: #82A1C4;
    padding: 50px 15px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.MAIN {
    max-width: 1024px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px; /* Added to match other sections */
}

.contacts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 10px; /* Added to align with other sections */
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #00428C;
    margin: 0;
}

.subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.0088em;
    text-transform: capitalize;
    color: white;
    margin: 0;
    max-width: 280px;
}

.find span {
    color: #FFD700;
}

.socials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
    color: #00428C;
}

.item a {
    color: white;
    text-decoration: none;
    font-family: 'Inter';
    font-size: 16px;
}

.mail-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00428C;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    width: 158px;
    height: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.mail-button:hover {
    background: #FFD700;
    color: #00428C;
}

/* Tablet */
@media (max-width: 1024px) {
    .MAIN {
        padding: 0 20px;
    }
    
    .socials {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Mobile */
@media (max-width: 640px) {
    .contact_container {
        padding: 30px 15px;
    }

    .MAIN {
        padding: 0;
    }

    .contacts {
        align-items: center;
        text-align: center;
        padding-left: 0; /* Remove padding on mobile to maintain center alignment */
    }

    .title, .subtitle {
        text-align: center;
    }

    .socials {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-content: start;
        text-align: start;
        
    }

    .item {
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .mail-button {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
}