.wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 16 / 9;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}