.aboutus-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-color: #82A1C4;
    padding: 15px;
    position: relative;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
}

.aboutus-main {
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
}

.aboutus-wrapper {
    max-width: 100%;
    width: 100%;
}

.aboutus-headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    gap: 12px;
}

.aboutus-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #00428C;
    margin: 0;
}

.aboutus-container {
    width: 100%;
    max-width: 100%;
}

.aboutus-content {
    width: 100%;
    max-width: 100%;
}

.aboutus-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0;
    text-align: center;
    letter-spacing: -0.0088em;
    color: #FFFFFF;
    width: 100%;
}

@media (min-width: 1024px) {
    .aboutus-main {
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        align-items: flex-start;
    }

    .aboutus-wrapper {
        flex: 0 0 42%;
        max-width: 42%;
    }

    .aboutus-headers {
        text-align: left;
        align-items: flex-start;
        margin: 0;
    }

    .aboutus-title {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        margin-left: 10px;
    }

    .aboutus-container {
        flex: 0 0 52%;
        max-width: 52%;
        margin-top: 5%;
    }

    .aboutus-content p {
        font-size: 22px;
        line-height: 28px;
        margin: 30px 0 30px 10px;
        text-align: left;
    }
}